import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import Loading from "./Components/Common/Loading";
import { HelmetProvider } from "react-helmet-async";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import "moment/locale/hu";
import dotenv from "dotenv";
import * as Sentry from "@sentry/react";
import path from "path";

dotenv.config({ path: path.resolve(process.cwd(), ".env") });
dotenv.config({ path: path.resolve(process.cwd(), ".env.local") });


if (process.env.REACT_APP_SENTRY_DNS) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        environment: process.env.REACT_APP_SENTRY_ENV_NAME,
        ignoreErrors: [
            'Request failed with status code 401',
            'AxiosError: Network Error',
            'Network Error',
            'Request failed with status code 422',
            'Request aborted',
            'Request failed with status code 502',
        ],
    });
    Sentry.setTag('host', process.env.REACT_APP_SENTRY_HOST);
}
ReactDOM.render(
    <MuiPickersUtilsProvider utils={DateMomentUtils} locale="hu">
        <Provider store={store}>
            <Suspense fallback={<Loading />}>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </Suspense>
        </Provider>
    </MuiPickersUtilsProvider>,
    document.getElementById("root")
);
reportWebVitals();
