import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Card from "../../Common/Card";
import Form from "../../Common/Form";
import { connect } from "react-redux";
import PageTemplate from "../../Common/PageTemplate";
import Filter from "../../Common/Filter";
import TextField from "../../Common/TextField";
import Table from "../../Common/Table";
import Select from "../../Common/Select";
import SubmitButton from "../../Common/SubmitButton";
import employersActions from "../../../store/employers/actions";
import providersActions from "../../../store/providers/actions";
import auditOfFinancingAccountsActions from "../../../store/auditOfFinancingAccounts/actions";
import consumptionDataActions from "../../../store/consumptionData/actions";
import axios from "axios";
import { MONTH_OPTIONS, numberFormat } from "../../../utils/AppConst";
import notificationActions from "../../../store/notification/actions";
import { setInfo } from "../../../utils/FilterHelper";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import { hasEmployers, hasServiceProvider, isAdmin } from "../../../utils/User";

function AuditOfFinancingAccounts(props) {
    const {
        filter,
        cachedFilter,
        employers,
        providers,
        pageLoading,
        tableLoading,
        searchData,
        detailsData,
        token,
        showSearchData,
        showDetailsData,
        years,
        tableInfo,
    } = props;

    useEffect(() => {
        props.defaultForm();
        axios.all([props.getEmployers(), props.getProviders(), props.getYears()]).then(
            axios.spread(() => {
                props.setPageLoading(false);
            })
        );

        if (!(!hasServiceProvider() || isAdmin())) props.changeForm({...filter, provider: hasServiceProvider().id}, "filter");
    }, []);

    const setInfoFirstDetails = (props, infokey, infoval) => {
        props.setInfo(infokey, infoval);
        const newInfo = Object.assign({}, props.info);
        newInfo[infokey] = infoval;

        for (const [key, value] of Object.entries(newInfo[infokey])) {
            if (Array.isArray(value)) {
                newInfo[infokey][key] = value.map((val) =>
                    val.value === undefined || val.value === null ? val : val.value
                );
            }
        }

        props.firstDetails(newInfo);
    };

    const detailsAction = () => {
        setInfoFirstDetails(props, "filter", cachedFilter);
    };

    const financierOptions = employers?.map((employer) => {
        return {
            value: employer.id,
            label: employer.name,
        };
    });

    const providerOptions = providers?.map((provider) => {
        return {
            value: provider.id,
            label: provider.name,
        };
    });

    const yearOptions = years?.map((year) => {
        return {
            value: year.id,
            label: year.name,
        };
    });

    const columnDefs = [
        {
            headerName: "Fogyasztási időszak",
            field: "consumptionPeriod",
            sort: "desc",
        },
        {
            headerName: "MAVIR ID",
            field: "mavirId",
            cellRenderer: "mavirIdCellRenderer",
        },
        { headerName: "Ügyfél neve", field: "clientsName" },
        { headerName: "Típus", field: "type" },
    ];

    const rowData = detailsData.map((data) => {
        return {
            consumptionPeriod: `${data.invoiceAccountingStartDate || ""} - ${data.invoiceAccountingEndDate || ""}`,
            mavirId: data.mavirId || "",
            clientsName: data.customer || "",
            type: data.type || "",
        };
    });

    return (
        <PageTemplate
            header={{
                title: "Finanszírozói számlaellenőrzés",
                breadcrumbs: {
                    finance: "Pénzügyek",
                    "audit-of-financing-accounts": "Számlaellenőrzés",
                },
            }}
            loading={pageLoading}
        >
            <Filter
                onSubmit={() => setInfo(props, "filter", filter)}
                onSubmitDisabled={!filter.financier || !filter.provider || !filter.year || !filter.month}
                defaultFormAction={() => {
                    props.defaultForm();
                }}
                form={filter}
                name="filter"
                changeform={props.changeForm}
            >
                {isAdmin() || hasServiceProvider() ? (
                    <Select
                        selectLabel="Finanszírozó"
                        name="financier"
                        optionList={financierOptions}
                        format={{ xs: 12, md: 4 }}
                        value={filter.financier || ""}
                    />
                ) : !hasEmployers() ? (
                    <TextField
                        label="Finanszírozó"
                        value={"A felhasználónál nincs megadva kapcsolattartó!"}
                        format={{ xs: 12, md: 4 }}
                        disabled
                    />
                ) : (
                    <Select
                        selectLabel="Finanszírozó"
                        name="financier"
                        optionList={hasEmployers().map(employer => ({
                            label: employer.name,
                            value: employer.id,
                        }))}
                        format={{ xs: 12, md: 4 }}
                        value={filter.financier || ""}
                    />
                )}
                {!hasServiceProvider() || isAdmin() ? (
                    <Select
                        selectLabel="Szolgáltató"
                        name="provider"
                        optionList={providerOptions}
                        format={{ xs: 12, md: 4 }}
                        value={filter.provider || ""}
                    />
                ) : (
                    <TextField
                        label="Szolgáltató"
                        value={hasServiceProvider().name}
                        format={{ xs: 12, md: 4 }}
                        disabled
                    />
                )}
                <Select
                    selectLabel="Év"
                    name="year"
                    optionList={yearOptions}
                    format={{ xs: 12, md: 2 }}
                    value={filter.year || ""}
                />
                <Select
                    selectLabel="Hónap"
                    name="month"
                    optionList={MONTH_OPTIONS}
                    format={{ xs: 12, md: 2 }}
                    value={filter.month || ""}
                    dontSort
                />
            </Filter>
            {showSearchData && (
                <Grid item xs={12}>
                    <Card title="Fogyasztási adatok alapján fizetendő">
                        <Grid item xs={12}>
                            <Form form={filter} changeform={props.changeForm} name="filter">
                                <TextField
                                    label="Finanszírozó"
                                    name="financier"
                                    value={searchData?.financier || ""}
                                    format={{ xs: 12, md: 6 }}
                                    disabled
                                />
                                <TextField
                                    label="Szolgáltató"
                                    name="provider"
                                    value={searchData?.serviceProvider || ""}
                                    format={{ xs: 12, md: 6 }}
                                    disabled
                                />
                                <TextField
                                    label="Áfa nélkül"
                                    name="net"
                                    value={`${numberFormat(searchData?.financierNetPrice || "")} Ft`}
                                    format={{ xs: 12, md: 6 }}
                                    disabled
                                />
                                <TextField
                                    label="Áfával"
                                    name="gross"
                                    value={`${numberFormat(searchData?.financierGrossPrice || "")} Ft`}
                                    format={{ xs: 12, md: 6 }}
                                    disabled
                                />
                                <TextField
                                    label="Számla esedékes"
                                    name="invoiceDue"
                                    value={`${searchData?.year || ""} ${
                                        MONTH_OPTIONS.find((month) => month.value == searchData?.month)?.text || ""
                                    }`}
                                    format={{ xs: 12 }}
                                    disabled
                                />
                                <SubmitButton
                                    onClick={() => detailsAction()}
                                    disabled={!filter.financier || !filter.provider || !filter.year || !filter.month}
                                >
                                    Részletezés
                                </SubmitButton>
                            </Form>
                        </Grid>
                    </Card>
                </Grid>
            )}
            {showDetailsData && (
                <Table
                    columnDefs={columnDefs}
                    rowData={rowData}
                    exportcontext={{
                        exportInitiate: props.exportInitiate,
                        exportCheck: props.exportCheck,
                        exportDownload: props.exportDownload,
                        filter: filter,
                        token: token,
                        info: tableInfo,
                    }}
                    frameworkComponents={{
                        mavirIdCellRenderer: MavirIdCellRenderer,
                    }}
                    filterData={filter}
                    searchfunc={props.details}
                    info={tableInfo}
                    setInfo={props.setInfo}
                    isPaginated={true}
                    loading={tableLoading}
                />
            )}
        </PageTemplate>
    );
}

function mapState(state) {
    const {
        form,
        filter,
        cachedFilter,
        pageLoading,
        tableLoading,
        setPageLoading,
        searchData,
        detailsData,
        token,
        showSearchData,
        showDetailsData,
        tableInfo,
    } = state.auditOfFinancingAccounts;
    const employers = state.employers.data;
    const providers = state.providers.data;
    const years = state.consumptionData.years;
    return {
        form,
        filter,
        cachedFilter,
        employers,
        providers,
        pageLoading,
        tableLoading,
        setPageLoading,
        searchData,
        detailsData,
        token,
        showSearchData,
        showDetailsData,
        years,
        tableInfo,
    };
}

const actionCreators = {
    changeForm: auditOfFinancingAccountsActions.changeForm,
    defaultForm: auditOfFinancingAccountsActions.defaultForm,
    getEmployers: () => employersActions.filter({}),
    getProviders: () => providersActions.filter({}),
    getYears: consumptionDataActions.getYears,
    setPageLoading: auditOfFinancingAccountsActions.setPageLoading,
    search: auditOfFinancingAccountsActions.search,
    firstDetails: auditOfFinancingAccountsActions.firstDetails,
    details: auditOfFinancingAccountsActions.details,
    exportInitiate: auditOfFinancingAccountsActions.exportInitiate,
    exportCheck: auditOfFinancingAccountsActions.exportCheck,
    exportDownload: auditOfFinancingAccountsActions.exportDownload,
    addNotification: notificationActions.addNotification,
    setInfo: auditOfFinancingAccountsActions.setInfo,
};

export default connect(mapState, actionCreators)(AuditOfFinancingAccounts);
