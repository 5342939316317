import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { withStyles } from "@material-ui/core";

const KeyboardArrowLeftIcon = withStyles({
    root: {
        fontSize: "medium",
        margin: "0 8px",
    },
})(KeyboardArrowLeft);

export default KeyboardArrowLeftIcon;
