import FirstPageIcon from "@material-ui/icons/FirstPage";
import { withStyles } from "@material-ui/core";

const StyledFirstPageIcon = withStyles({
    root: {
        fontSize: "medium",
        margin: "0 8px",
    },
})(FirstPageIcon);

export default StyledFirstPageIcon;
