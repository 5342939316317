import { PERMISSIONS } from "./AppConst";

/**
 *
 * @returns Local storage-ban tárolt felhasználó
 */
export const getUser = () => {
    const item = localStorage.getItem("user");
    if (!item) {
        return null;
    }
    return JSON.parse(item);
};

/**
 *
 * @returns Felhasználó azonosítója
 */
export const getUserId = () => {
    const user = getUser();
    if (!user) return false;
    return user?.id;
};

/**
 *
 * @param {*} role - Vizsgálandó szerepkör
 * @returns Van-e a felhasználónak ilyen jogosultsága vagy nincs
 */
export const hasRole = (role) => {
    const user = getUser();
    if (!user) {
        return false;
    }
    return user?.roles?.includes(role);
};

/**
 *
 * @returns A felhasználónak van-e admin jogosultsága
 */
export const isAdmin = () => {
    return hasRole(PERMISSIONS.ADMIN);
};

/**
 *
 * @returns A felhasználónak van-e szolgáltató jogosultsága
 */
export const hasServiceProvider = () => {
    const user = getUser();
    if (!user) return false;
    return user?.serviceProvider;
};

/**
 *
 * @returns A felhasználónak megadott kapcsolattartói finanszírozókat / alkalmazókat
 */
export const hasEmployers = () => {
    const user = getUser();
    if (!user || !user.employers || user.employers.length === 0) return false;
    return user.employers;
};

/**
 * A function that checks if the user has only one role and that role is the given role.
 */
export const hasOnlyRole = (role) => {
    const user = getUser();
    if (!user) return false;
    return user?.roles?.length === 1 && user?.roles?.includes(role);
};
