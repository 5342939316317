import LastPageIcon from "@material-ui/icons/LastPage";
import { withStyles } from "@material-ui/core";

const StyledLastPageIconIcon = withStyles({
    root: {
        fontSize: "medium",
        margin: "0 8px",
    },
})(LastPageIcon);

export default StyledLastPageIconIcon;
