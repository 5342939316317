import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { withStyles } from "@material-ui/core";

const KeyboardArrowRightIcon = withStyles({
    root: {
        fontSize: "medium",
        margin: "0 8px",
    },
})(KeyboardArrowRight);

export default KeyboardArrowRightIcon;
